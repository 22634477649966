<!--
 * @Description:  
 * @Author: cuncai.zhang@simceredx.com
 * @Date: 2023-06-16 15:11:09
 * @LastEditTime: 2023-11-08 13:32:42
 * @LastEditors: cuncai.zhang@simceredx.com
-->
<template>
  <div class="content">
    <el-card shadow="never" class="new-card" style="margin-top: 24px">
      <div slot="header" class="card-header">
        <div class="card-header-left">
          <div class="top-title">创建角色</div>
        </div>
      </div>
      <div>
        <el-form
          ref="ruleForm"
          :model="userForm"
          label-width="120px"
          :rules="rules"
          style="width: 800px; margin: 0 auto"
        >
          <el-form-item label="角色名称" prop="roleName">
            <el-input
              v-model="userForm.roleName"
              clearable
              style="width: 500px"
              placeholder="请输入角色名称"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="角色说明" prop="roleDescribe">
            <el-input
              type="textarea"
              v-model="userForm.roleDescribe"
              style="width: 500px"
              clearable
              maxlength="200"
              :rows="6"
              show-word-limit
              placeholder="请输入角色说明"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="权限目录"
            style="margin-left: 6px"
            prop="auth_ids"
          >
            <el-cascader
              collapse-tags
              style="width: 500px"
              placeholder="请选择权限目录"
              class="auth-show-input"
              v-model="userForm.auth_ids"
              :options="menus"
              :props="{ multiple: true, label: 'menuName', value: 'id' }"
              @change="(node) => selectHandle(node, 'auth_ids')"
            >
              <template #default="{ node, data }">
                <span>{{ data.menuName }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item
            label="设置目标权限"
            style="margin-left: 5px"
            prop="setTargetPermissions"
          >
            <el-cascader
              collapse-tags
              placeholder="设置目标权限"
              class="auth-show-input"
              v-model="userForm.setTargetPermissions"
              :options="targetPermissions"
              style="width: 500px"
              :props="{ multiple: true, label: 'menuName', value: 'id' }"
              @change="(node) => selectHandle(node, 'setTargetPermissions')"
            >
              <template #default="{ data }">
                <span>{{ data.menuName }}</span>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item>
            <el-radio-group v-model="activeName">
              <el-radio-button label="收入口径权限"></el-radio-button>
              <el-radio-button label="营管口径权限"></el-radio-button>
            </el-radio-group>
          </el-form-item>

          <div v-show="activeName === '收入口径权限'">
            <el-form-item
              label="模块权限"
              style="margin-left: 5px"
              prop="expenseDepartmentLevel"
            >
              <div class="modular-box">
                <div>交易分析团队层级</div>
                <el-radio-group v-model="userForm.expenseDepartmentLevel">
                  <el-radio :label="1">一级</el-radio>
                  <el-radio :label="2">二级</el-radio>
                  <el-radio :label="3">三级</el-radio>
                  <el-radio :label="4">四级</el-radio>
                  <el-radio :label="5">五级</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>

            <el-form-item
              label="组织架构"
              style="margin-left: 6px"
              prop="auth_data"
            >
              <el-cascader
                collapse-tags
                style="width: 500px; margin-left: 6px"
                v-model="userForm.auth_data"
                :options="jg"
                :props="{
                  multiple: true,
                  label: 'name',
                  value: 'code',
                }"
                placeholder="请选择组织架构"
                @change="(node) => selectHandle(node, 'auth_data')"
              >
                <template #default="{ node, data }">
                  <span>{{ data.name }}</span>
                  <span v-if="data.delFlag != 0">(旧)</span>
                  <span v-if="!node.isLeaf">
                    ({{ data.children.length }})
                  </span>
                </template>
              </el-cascader>
            </el-form-item>
            <el-form-item
              label="产品权限"
              style="margin-left: 6px"
              prop="auth_goodsType"
            >
              <el-select
                v-model="userForm.auth_goodsType"
                placeholder="请选择"
                style="width: 500px"
                multiple
                @change="(val) => changeSelect(val, 'goodsType')"
              >
                <el-checkbox
                  v-model="goodsTypeChecked"
                  @change="selectAllGoodsType"
                  style="margin-left: 20px"
                >
                  全选
                </el-checkbox>
                <el-option
                  v-for="(item, index) in goodsType"
                  :key="index"
                  :label="item.name"
                  :value="item.typeId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="客户权限"
              style="margin-left: 5px"
              prop="doctorIds"
            >
              <el-cascader
                collapse-tags
                placeholder="客户权限"
                class="auth-show-input"
                v-model="userForm.doctorIds"
                :options="doctors"
                style="width: 500px"
                :props="cascaderProps"
                :show-all-levels="false"
                @change="(node) => selectHandle(node, 'doctorIds')"
                ref="myCascader"
              >
                <template #default="{ data }">
                  <span>{{ data.regionName }}</span>
                </template>
              </el-cascader>
            </el-form-item>

            <el-form-item
              label="人员身份"
              style="margin-left: 6px"
              prop="personnelIdentity"
            >
              <el-select
                v-model="userForm.personnelIdentity"
                placeholder="请选择"
                multiple
                style="width: 500px"
                @change="(val) => changeSelect(val, 'personnelIdentity')"
              >
                <el-checkbox
                  v-model="personnelIdentityChecked"
                  @change="selectAllPersonnelIdentityChecked"
                  style="margin-left: 20px"
                >
                  全选
                </el-checkbox>
                <el-option
                  v-for="(item, index) in personnelIdentity"
                  :key="index"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-show="activeName === '营管口径权限'">
            <el-form-item
              label="组织架构"
              style="margin-left: 6px"
              prop="marketAuthDept"
            >
              <el-cascader
                ref="authDeptRef"
                collapse-tags
                style="width: 500px; margin-left: 6px"
                v-model="selectMarketAuth"
                :options="marketJg"
                :props="{
                  multiple: true,
                  label: 'deptName',
                  value: 'deptCode',
                  children: 'subList',
                }"
                placeholder="请选择组织架构"
                @change="onSelectMarketDept"
              >
                <template #default="{ node, data }">
                  <span>{{ data.deptName }}</span>
                  <span v-if="!node.isLeaf"> ({{ data.subList.length }}) </span>
                </template>
              </el-cascader>
            </el-form-item>

            <el-form-item
              label="数据口径"
              style="margin-left: 6px"
              prop="dataRoad"
            >
              <el-radio-group v-model="userForm.marketDataScope">
                <el-radio :label="1">地区口径</el-radio>
                <el-radio :label="2">大区口径</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>

          <el-form-item>
            <el-button @click="resetForm('ruleForm')">取 消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确 定</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import store from "@/store";
import { uniq } from "lodash";
export default {
  data() {
    return {
      selected: [],
      activeName: "收入口径权限",
      formInline: {
        roleName: "",
      },

      select_val: "username",
      seachVal: "",
      cascaderProps: {
        before_auth_ids: "",
        multiple: true,
        label: "regionName",
        value: "regionCode",
      },

      tableData: [], // 表格数据
      jg: [], // 组织架构
      marketJg: [], // 营管口径组织架构
      goodsType: [], //产品线
      menus: [], //目录权限
      personnelIdentity: {}, //人员神烦
      targetPermissions: [], //目标权限
      rules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
      },
      selectMarketAuth: [],
      testArr: [],
      userForm: {
        roleDescribe: "", //角色描述
        roleName: "", //角色名称
        auth_ids: [], //目录权限
        auth_data: [], //组织架构权限
        marketDataScope: "", // 数据口径
        marketAuthDept: "", // 数据口径
        setTargetPermissions: [], // 是否设置目标
        auth_goodsType: [], //产品线
        doctorIds: [],
        expenseDepartmentLevel: "",
        personnelIdentity: [],
      }, // 表单信息
      deleteArray: [], // 删除数组
      tableHeight: "850px",
      select_modular: 1, // 交易分析团队层级
      doctors: [],
      addressParams: {
        divCode: "101",
      },
      //目录全选
      auth_idsPreSelected: [], // 上次选中的数据
      auth_idsOriginData: [], //  源数据平铺成一级节点
      menuAll: [{ menuName: "全选", id: "999999" }],

      //组织架构
      auth_dataPreSelected: [], // 上次选中的数据
      auth_dataOriginData: [], //  源数据平铺成一级节点
      auth_dataAll: [{ name: "全选", code: "999999", delFlag: 0 }],

      //客户全选
      doctorIdsPreSelected: [], // 上次选中的数据
      doctorIdsOriginData: [], //  源数据平铺成一级节点
      customerAll: [{ regionName: "全选", regionCode: "999999" }],

      //目标全选
      setTargetPermissionsPreSelected: [], // 上次选中的数据
      setTargetPermissionsOriginData: [], //  源数据平铺成一级节点
      setTargetPermissionsAll: [{ menuName: "全选", id: "999999" }],

      // 产品全线
      goodsTypeChecked: false, //全选
      // 人员身份全选
      personnelIdentityChecked: false,
    };
  },
  watch: {
    test: {
      deep: true, // deep: true  // 可以深度检测到 test 对象的属性值的变化
      handler(newV) {
        this.test = newV;
      },
    },
  },
  created() {
    this.getJg();
    console.log(this.$route.params.id);
  },
  mounted() {
    // 获取高度
    this.tableHeight = document.body.clientHeight - 210 + "px";

    // 全选的数据
    this.oneDimensionalList = [];
    // if (this.$route.params.id) {
    //   this.getDetail(this.$route.params.id);
    // }
  },
  methods: {
    onSelectMarketDept() {
      this.$nextTick(() => {
        this.selected = [];
        const checkedNodes = this.$refs.authDeptRef.getCheckedNodes();
        const checkedPath = checkedNodes.map((item) => item.path);
        let checkedFullPath = checkedNodes.map((item) => item.path.join("-"));
        for (let j in checkedPath) {
          if (checkedFullPath.includes(checkedPath[j].join("-"))) {
            this.selected.push(checkedPath[j][checkedPath[j].length - 1]);
            checkedFullPath = checkedFullPath.filter(
              (item) => !item.includes(checkedPath[j].join("-"))
            );
          }
        }
        console.log("selected", uniq(this.selected));
      });
    },
    changeTab(tab, event) {
      console.log("val", this.activeName);
    },
    getCheckedTrees(str, trees, result = []) {
      for (let j in trees) {
        if (
          trees[j].fullPathCode.includes(str) ||
          str.includes(trees[j].fullPathCode)
        ) {
          result.push(trees[j].fullPathCode);
          if (trees[j]["subList"]) {
            this.getCheckedTrees(str, trees[j]["subList"], result);
          }
        }
      }
      return result;
    },
    getDetail(id) {
      let data = {
        id: parseInt(id),
      };
      this.axios.post("QUERY_BY_ID", data).then((result) => {
        console.log("角色详情", result);
        let {
          authData,
          authDoctor,
          authGoodsType,
          authIds,
          roleDescribe,
          roleName,
          setTargetPermissions,
          expenseDepartmentLevel,
          personnelIdentity,
          marketAuthDept,
          marketDataScope,
          marketAuthDeptSelectedList,
        } = result.data;
        this.userForm = {
          auth_ids: authIds ? JSON.parse(authIds) : "",
          doctorIds: authDoctor ? JSON.parse(authDoctor) : "",
          auth_goodsType: authGoodsType ? JSON.parse(authGoodsType) : "",
          setTargetPermissions: setTargetPermissions
            ? JSON.parse(setTargetPermissions)
            : "",
          roleName,
          roleDescribe,
          expenseDepartmentLevel: expenseDepartmentLevel,
          auth_data: authData ? JSON.parse(authData) : "",
          personnelIdentity: personnelIdentity
            ? JSON.parse(personnelIdentity)
            : "",
          marketDataScope,
        };
        const needData = [];
        for (let i in marketAuthDeptSelectedList) {
          needData.push(
            this.getCheckedTrees(marketAuthDeptSelectedList[i], this.marketJg)
          );
        }
        const lastData = needData.flat(5);
        this.selectMarketAuth = lastData.map((item) => {
          const arr = item.split("-");
          arr.shift();
          return arr;
        });
        this.selected = marketAuthDept.split(",");
        this.changeSelect(this.userForm.auth_goodsType, "goodsType");
        this.changeSelect(this.userForm.personnelIdentity, "personnelIdentity");
      });

      console.log(this.userForm.doctorIds);
    },
    // 创建编辑提交
    submitForm() {
      this.$refs.ruleForm.validate((e) => {
        if (!e) return;
        // 菜单
        console.log(this.userForm);
        let data = {
          roleName: this.userForm.roleName,
          roleDescribe: this.userForm.roleDescribe,
          id: parseInt(this.$route.params.id),
          // authIds: menu.join(','),
          authIds: this.userForm.auth_ids.length
            ? JSON.stringify(this.userForm.auth_ids)
            : "",
          authGoodsType: this.userForm.auth_goodsType.length
            ? JSON.stringify(this.userForm.auth_goodsType)
            : "",
          authData: this.userForm.auth_data.length
            ? JSON.stringify(this.userForm.auth_data)
            : "",
          setTargetPermissions: this.userForm.setTargetPermissions.length
            ? JSON.stringify(this.userForm.setTargetPermissions)
            : "",
          expenseDepartmentLevel: this.userForm.expenseDepartmentLevel,
          authDoctor: this.userForm.doctorIds.length
            ? JSON.stringify(this.userForm.doctorIds)
            : "",
          // personnelIdentity: JSON.stringify(this.userForm.personnelIdentity),
          personnelIdentity: this.userForm.personnelIdentity.length
            ? JSON.stringify(this.userForm.personnelIdentity)
            : "",
          marketDataScope: this.userForm.marketDataScope,
          marketAuthDept: uniq(this.selected).join(","),
        };
        console.log("param", data, this.$route.params.id);
        if (parseInt(this.$route.params.id) == 0) {
          delete data.id;
        }
        console.log("param", data);
        // return
        this.axios.post("NEW_SAVE_USER", data).then(() => {
          // 成功提示
          this.$message({
            type: "success",
            message: "保存成功",
          });
          let userName = JSON.parse(
            JSON.stringify(sessionStorage.getItem("userInfo"))
          ).userName;
          this.$router.push(`/auth`);
        });
      });
    },
    resetForm(formName) {
      console.log(formName);
      this.$refs[formName].resetFields();
      this.$router.push(`/auth`);
    },
    // 生成交易分析团队层级权限
    getModelAuthStr() {
      let array = [];
      console.log(this.userForm.auth_data);
      // 根据数据权限设置模块权限
      this.userForm.auth_data.forEach((v) => {
        let arr = array.filter((val) => val.id == v[this.select_modular]);
        if (arr.length <= 0) {
          if (v[this.select_modular]) {
            array.push({
              id: v[this.select_modular],
              name: this.getName(this.jg, v[this.select_modular]),
            });
          }
        }
      });
      return JSON.stringify(array);
    },
    // 根据组织架构ID查名称
    getName(list, id) {
      let _this = this;
      for (let i = 0; i < list.length; i++) {
        let a = list[i];
        if (a.deptId == id) {
          return a.deptName;
        } else {
          if (a.children && a.children.length > 0) {
            let res = _this.getName(a.children, id);
            if (res) {
              return res;
            }
          }
        }
      }
    },
    // 获取组织架构
    getJg() {
      this.axios.post("NEW_JG_LIST").then((result) => {
        console.log("组织架构", result);
        let {
          goodsType,
          menu,
          targetPermissions,
          doctors,
          team,
          personnelIdentity,
          marketAuthDeptList,
        } = result.data;
        this.jg = [...this.auth_dataAll, ...team];
        this.marketJg = marketAuthDeptList;
        this.goodsType = goodsType;
        this.menus = [...this.menuAll, ...menu];
        this.targetPermissions = [
          ...this.setTargetPermissionsAll,
          ...targetPermissions,
        ];
        this.doctors = [...this.customerAll, ...doctors];
        this.personnelIdentity = personnelIdentity;
        if (this.$route.params.id && this.$route.params.id !== "0") {
          this.getDetail(this.$route.params.id);
        }
      });
    },
    // +++++++++++++++++++++++++++
    //全选start
    judgetAllSelected(node) {
      // 判断是否是全选,也就是看已选择的选中中包不包含"全选"
      let isAllSelected = false;
      for (let i = 0; i < node.length; i++) {
        if (node[i][0] === "999999") {
          isAllSelected = true;
          break;
        }
      }
      return isAllSelected;
    },
    loopFlatData(list = [], parentNode = [], valueName) {
      console.log(list, "loopFlatData");
      list.length > 0 &&
        list.forEach((e) => {
          let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
          if (e.children && e.children.length > 0) {
            if (valueName == "doctorIds") {
              pNode.push(e.regionCode); // 1 11
            } else if (valueName == "auth_ids") {
              pNode.push(e.id);
            } else if (valueName == "auth_data") {
              pNode.push(e.code);
            } else if (valueName == "setTargetPermissions") {
              pNode.push(e.id);
            }
            this.loopFlatData(e.children, pNode, valueName);
          } else {
            if (
              (valueName == "doctorIds" && e.regionCode !== "999999") ||
              (valueName == "auth_ids" && e.id !== "999999") ||
              (valueName == "auth_data" && e.code !== "999999") ||
              (valueName == "setTargetPermissions" && e.id !== "999999")
            ) {
              if (parentNode.length > 0) {
                if (valueName == "doctorIds") {
                  pNode.push(e.regionCode);
                } else if (valueName == "auth_ids") {
                  pNode.push(e.id);
                } else if (valueName == "auth_data") {
                  pNode.push(e.code);
                } else if (valueName == "setTargetPermissions") {
                  pNode.push(e.id);
                }
                this[`${valueName}OriginData`].push({
                  ...e,
                  parentNode: pNode,
                });
              } else {
                this[`${valueName}OriginData`].push(e);
              }
            }
          }
        });
    },
    loopSelectData(list, parentNode = [], valueName) {
      list.length > 0 &&
        list.forEach((e) => {
          let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新

          if (e.children && e.children.length > 0) {
            if (valueName == "doctorIds") {
              pNode.push(e.regionCode); // 1 11
            } else if (valueName == "auth_ids") {
              pNode.push(e.id); // 1 11
            } else if (valueName == "auth_data") {
              pNode.push(e.code); // 1 11
            } else if (valueName == "setTargetPermissions") {
              pNode.push(e.id); // 1 11
            }
            this.loopSelectData(e.children, pNode, valueName);
          } else {
            if (parentNode.length > 0) {
              if (valueName == "doctorIds") {
                this.userForm[valueName].push([...parentNode, e.regionCode]);
              } else if (valueName == "auth_ids") {
                this.userForm[valueName].push([...parentNode, e.id]);
              } else if (valueName == "auth_data") {
                this.userForm[valueName].push([...parentNode, e.code]);
              } else if (valueName == "setTargetPermissions") {
                this.userForm[valueName].push([...parentNode, e.id]);
              }
            } else {
              if (valueName == "doctorIds") {
                this.userForm[valueName].push([e.regionCode]);
              } else if (valueName == "auth_ids") {
                this.userForm[valueName].push([e.id]);
              } else if (valueName == "auth_data") {
                this.userForm[valueName].push([e.code]);
              } else if (valueName == "setTargetPermissions") {
                this.userForm[valueName].push([e.id]);
              }
            }
          }
        });
    },
    checkIsAddAllSelected(valueName) {
      let list = "";
      if (valueName == "doctorIds") {
        list = this.doctors;
      } else if (valueName == "auth_ids") {
        list = this.menus;
      } else if (valueName == "auth_data") {
        list = this.jg;
      } else if (valueName == "setTargetPermissions") {
        list = this.targetPermissions;
      }
      if (this[`${valueName}OriginData`].length === 0) {
        this.loopFlatData(list, [], valueName); // 把所有的父子级平铺成一个一级列表
      }
      let origin = this[`${valueName}OriginData`];
      console.log(this.userForm[valueName]);
      let now = [...this.userForm[valueName]].filter(
        (item) => item[0] !== "999999"
      );
      console.log(now, "this.userForm.doctorIds", origin);
      if (origin.length > now.length) {
        // 非全选时, 如果有之前选过全选,要把全选过滤掉
        this.userForm[valueName] = this.userForm[valueName].filter(
          (item) => item[0] !== "999999"
        );
        console.log(this.userForm[valueName]);
      } else {
        console.log("--------------------------");
        // 当所有的数据都选择时, 要自动把全选勾选上
        if (
          this.userForm[valueName] &&
          this.userForm[valueName][0][0] !== "999999"
        ) {
          this.userForm[valueName] = [["999999"], ...this.userForm[valueName]];
        }
      }
    },
    async selectHandle(node = [], valueName) {
      // debugger
      this.userForm[valueName] = [];
      console.log(valueName);
      let list = "";
      if (valueName == "doctorIds") {
        list = this.doctors;
      } else if (valueName == "auth_ids") {
        list = this.menus;
      } else if (valueName == "auth_data") {
        list = this.jg;
      } else if (valueName == "setTargetPermissions") {
        list = this.targetPermissions;
      }
      console.log(list, list.length);
      let current = []; // 获取当前选中的option, 因为element文档中没有获取当前选中的option的方法,所以我通过上一次和本地的选中数据进行对比来获取
      if (node.length >= this[`${valueName}PreSelected`].length) {
        let keys = this[`${valueName}PreSelected`].map((item) =>
          JSON.stringify(item)
        );
        current = node.filter((item) => !keys.includes(JSON.stringify(item)));
        console.log("选中某项", current);
      } else {
        // 取消选中
        let keys = node.map((item) => JSON.stringify(item));
        current = this[`${valueName}PreSelected`].filter(
          (item) => !keys.includes(JSON.stringify(item))
        );
        console.log("取消选中", current);
      }
      // 根据element的选中数据格式, 每一个选项都是一个列表, 列表第一项为父级value, 第二项为选中的子级value, ...以此类推
      const currentValue = current.length > 0 ? current[0][0] || "" : "";
      console.log(currentValue, node);
      if (currentValue === "999999") {
        console.log(this.judgetAllSelected(node));
        if (this.judgetAllSelected(node)) {
          this.loopSelectData(list, [], valueName);
        } else {
          this.userForm[valueName] = [];
        }
      } else {
        console.log(2222222222);
        this.userForm[valueName] = node;
      }
      this.checkIsAddAllSelected(valueName); // 主要是勾选或取消非“全选”项时，对于全选的逻辑处理
      this[`${valueName}PreSelected`] = this.userForm[valueName]; // 保存上一次的选择结果
    },
    changeSelect(val, valName) {
      console.log(val);
      if (valName == "goodsType") {
        if (val.length === this.goodsType.length) {
          this.goodsTypeChecked = true;
        } else {
          this.goodsTypeChecked = false;
        }
      } else {
        let mapLength = Object.keys(this.personnelIdentity).length;
        console.log(mapLength, this.personnelIdentity);
        if (val.length === mapLength) {
          this.personnelIdentityChecked = true;
        } else {
          this.personnelIdentityChecked = false;
        }
      }
    },
    selectAllGoodsType() {
      this.userForm.auth_goodsType = [];
      if (this.goodsTypeChecked) {
        this.goodsType.map((item) => {
          this.userForm.auth_goodsType.push(item.typeId);
        });
      } else {
        this.userForm.auth_goodsType = [];
      }
      this.$emit("changeSelect", {
        val: this.userForm.auth_goodsType,
        index: this.index,
      });
    },
    selectAllPersonnelIdentityChecked() {
      this.userForm.personnelIdentity = [];
      if (this.personnelIdentityChecked) {
        console.log(this.personnelIdentity);
        for (var key in this.personnelIdentity) {
          this.userForm.personnelIdentity.push(key);
        }
      } else {
        this.userForm.personnelIdentity = [];
      }
      this.$emit("changeSelect", {
        val: this.userForm.personnelIdentity,
        index: this.index,
      });
    },

    //全选end
  },
};
</script>

<style>
.modular-box {
  display: flex;
  flex-direction: column;
}
.el-cascader__tags .el-tag {
  color: #909399;
}
.new-card .el-card__header {
  padding: 13px 20px;
}
.new-card .el-card__body {
  padding: 20px;
  /* background: #F0F2F5; */
}
.el-dialog__headerbtn .el-dialog__close {
  color: #1cd097;
}
.el-table::before {
  z-index: inherit;
}
.new-tag1 {
  border-radius: 4px;
  background: #2672ff10;
  color: #2672ff;
  border: 1px solid #2672ff50;
  padding: 4px 10px;
}
.new-tag2 {
  border-radius: 4px;
  background: #ffa50f10;
  color: #ffa50f;
  border: 1px solid #ffa50f50;
  padding: 4px 10px;
}
.new-tag3 {
  border-radius: 4px;
  background: #ff532210;
  color: #ff5322;
  border: 1px solid #ff532250;
  padding: 4px 10px;
}
.new-tag4 {
  border-radius: 4px;
  background: #9d4de610;
  color: #9d4de6;
  border: 1px solid #9d4de650;
  padding: 4px 10px;
}
.new-tag5 {
  border-radius: 4px;
  background: #1cd09710;
  color: #1cd097;
  border: 1px solid #1cd09750;
  padding: 4px 10px;
  width: 90px;
  margin: 0 auto;
}
</style>

<style lang="scss" scoped>
.content {
  overflow-y: auto;
  height: 90vh;
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-title {
        font-size: 16px;
        color: #262626;
        font-weight: bold;
      }
      .question {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
      .question:hover {
        cursor: pointer;
      }
    }
    .card-header-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .auth-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .auth-show-input {
    width: 300px;
  }
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
